<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :trigger="null"
    :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
  >
    <div class="brand">
      <img src="/images/logo-ct-black.png" alt="" /> <span>SIPULPEN</span>
    </div>
    <hr />
    <a-divider
      ><p style="text-transform: uppercase">
        {{ $auth.user.role.name }}
      </p></a-divider
    >
    <side-bar-menu></side-bar-menu>
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
import SideBarMenu from '@/components/Sidebars/SideBarMenu.vue'
export default {
  components: { SideBarMenu },
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: 'primary',
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      // sidebarCollapsedModel: this.sidebarCollapsed
    }
  },
}
</script>
