<template>
  <!-- Layout Header ( Navbar ) -->
  <a-layout-header>
    <div class="header-col header-brand">
      <h6>SIPULPEN</h6>
      <!-- Trigger Button For Navigation Menu For Small Screens -->
      <a-button type="link" class="btn-menu-trigger" href="/">
        <font-awesome-icon
          icon="fa-solid fa-gauge"
          size="xl"
          :style="{ color: 'black' }"
        ></font-awesome-icon>
        <span :style="{ color: 'black' }">Dashboard</span>
      </a-button>
      <!-- Trigger Button For Navigation Menu For Small Screens -->
    </div>
    <div class="header-col header-nav">
      <span class="menu-large">
        <a-row justify="end">
          <a-col :span="24" style="text-align: right">
            <a-button type="link" href="/">
              <font-awesome-icon
                icon="fa-solid fa-gauge"
                size="xl"
                :style="{ color: 'black' }"
              ></font-awesome-icon>
              <span :style="{ color: 'black' }">Dashboard</span></a-button
            >
          </a-col>
        </a-row>
      </span>
    </div>
  </a-layout-header>
  <!-- / Layout Header ( Navbar ) -->
</template>

<script>
export default {
  data() {
    return {
      // Collapse navigation value.
      // Binded model property for "Collapsible Navigation Menu" collapsed status .
      collapseNav: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-link svg {
  margin-right: 5px;
  vertical-align: middle;
}
.nav-link span {
  vertical-align: middle;
}
.ant-menu-submenu-popup {
  width: 100%;
}
</style>