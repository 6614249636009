<template>
  <!-- Layout Footer -->
  <a-layout-footer>
    <p class="copyright">
      Copyright © 2022 (Sipulpen) BKPSDM KABUPATEN KARAWANG
      <a href="https://creative-tim.com">Creative Tim</a>.
    </p>
  </a-layout-footer>
  <!-- / Layout Footer -->
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>
