export const state = () => ({
  userRealName: null,
  userName: null,
  userEmail: null,
  userId: null,
  userJabatan: null,

  userGolonganId: null,
  userGolongan: null,
  userTmtGolongan: null,
  userPangkat: null,

  userRole: null,
  userRoleId: null,
  userAvatar: null,
  userJabatanDetail: {
    unit_kerja: {
      nama: null,
    },
  },
  userPendidikanTerakhir: {
    id: null,
    intansi: null,
    jenjang: null,
    jurusan: null,
    tahun_masuk: null,
    tahun_lulus: null,
    tingkat: null,
  },
  userProfile: {},
  dataKepsek: null,
  simAsnPegawaiId: null,
  pengaturan: {
    id: null,
    pejabat_id: null,
    tanggal_pak: null,
    tanggal_tms_pak: null,
    status: false,
    maintenance: false,
  },
})

export const getters = {
  loggedIn(state) {
    return state.auth.loggedIn
  },
  user(state) {
    return state.auth.user
  },
  dataKepsek(state) {
    return state.dataKepsek
  },
  userRole(state) {
    return state.userRole
  },
  pengaturan(state) {
    return state.pengaturan
  },
}
export const mutations = {
  basic(state, payload) {
    state[payload.key] = payload.value
  },
  pengaturan(state, payload) {
    if (payload) {
      state.pengaturan = payload
    }
  },
  user(state, payload) {
    // console.log(payload)

    if (payload.id) {
      state.userId = payload.id
    }
    if (payload.pegawai_id) {
      state.simAsnPegawaiId = payload.pegawai_id
    }
    if (payload.name) {
      state.userRealName = payload.name
    }
    if (payload.username) {
      state.userName = payload.username
    }
    if (payload.email) {
      state.userEmail = payload.email
    }
    if (payload.role_id) {
      state.userRoleId = payload.role_id
    }
    if (payload.role) {
      state.userRole = payload.role.name
    }
    if (payload.photo) {
      state.userAvatar = payload.photo
    }
    if (payload.pegawai) {
      if (payload.pegawai.jabatan) {
        const jabatan = payload.pegawai.jabatan
        state.userJabatan = jabatan.nama
        state.userJabatanDetail = jabatan
      }

      if (payload.pegawai.unit_kerja) {
        const jabatan = {
          ...state.userJabatanDetail,
          unit_kerja: payload.pegawai.unit_kerja,
        }
        state.userJabatanDetail = jabatan
      }

      if (payload.pegawai.golongan) {
        state.userGolonganId = payload.pegawai.golongan.referensi.id
        state.userGolongan = payload.pegawai.golongan.referensi.golongan
        state.userTmtGolongan = payload.pegawai.golongan.tanggal_mulai
        state.userPangkat = payload.pegawai.golongan.referensi.pangkat
      }

      if (payload.pegawai.pendidikan_terakhir) {
        state.userPendidikanTerakhir = payload.pegawai.pendidikan_terakhir
      }

      state.userProfile = payload.pegawai
    }
  },

  dataKepsek(state, payload) {
    state.dataKepsek = payload
  },
  resetState(state) {
    // acquire initial state
    const s = state
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}
