import Vue from 'vue'
import Vueditor from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'
// your config here
const config = {
    toolbar: [
        'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor', 'divider',
        'bold', 'italic', 'underline', 'strikeThrough', 'links', 'divider', 'subscript', 'superscript',
        'divider', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', '|', 'indent', 'outdent',
        'insertOrderedList', 'insertUnorderedList', '|', 'picture', 'tables', '|', 'switchView'
    ],
    fontName: [
        { val: 'arial black' },
        { val: 'times new roman' },
        { val: 'Courier New' }
    ],
    fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
    uploadUrl: ''
};
Vue.use(Vueditor, config);

// import Antd from 'ant-design-vue/lib'
// import { FormModel } from 'ant-design-vue';

// Vue.use(Antd)
// Vue.use(FormModel);

// Vue.config.productionTip = false

