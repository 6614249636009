export default [
  {
    label: 'Dashboard',
    key: 'dashboard',
    icon: 'fa-solid fa-gauge',
    link: '/',
    permission: 'all',
  },
  {
    label: 'Verifikasi Usul PAK',
    key: 'verifikasi_usul_pak',
    icon: 'fa-solid fa-pencil',
    link: '/data-pak/table/usul-pak',
    permission: 'admin,sekretariat',
  },
  {
    label: 'Input Dupak',
    key: 'data_dupak',
    icon: 'fa-solid fa-table',
    link: '/data-dupak/table-user',
    permission: 'user',
  },
  {
    label: 'Data Pak',
    key: 'data_pak',
    icon: 'fa-solid fa-table',
    link: '/data-pak/table/user',
    permission: 'user',
  },
  {
    label: 'Usul Pak',
    key: 'usul_pak',
    icon: 'fa-solid fa-table',
    link: '/pak/usul',
    permission: 'user',
  },
  {
    label: 'Data Dupak Admin',
    key: 'data_dupak_admin',
    icon: 'fa-solid fa-table',
    link: '/data-dupak/table-admin',
    permission: 'admin',
  },
  {
    label: 'Distribusi Dupak',
    key: 'data_dupak_sekretariat',
    icon: 'fa-solid fa-table',
    link: '/data-dupak/table-distribusi',
    permission: 'sekretariat,admin',
  },
  {
    label: 'Verifikasi Dupak',
    key: 'data_dupak_verifikasi',
    icon: 'fa-solid fa-pencil',
    link: '/data-dupak/table-verifikasi',
    permission: 'sekretariat,admin',
  },
  {
    label: 'Print PAK',
    key: 'print_pak',
    icon: 'fa-solid fa-table',
    link: '/data-pak/table/print-pak',
    permission: 'sekretariat,admin',
  },
  {
    label: 'PAK Integrasi',
    key: 'pak_integrasi',
    icon: 'fa-solid fa-table',
    link: '/data-pak/table/pak-integrasi',
    permission: 'sekretariat,admin',
  },
  {
    label: 'Data Pak',
    key: 'data_pak_sekretariat',
    icon: 'fa-solid fa-table',
    link: '/data-pak/table/sekretariat',
    permission: 'sekretariat,admin',
  },
  {
    label: 'Data Dokumen Pak',
    key: 'data_dokumen_pak_sekretariat',
    icon: 'fa-solid fa-file',
    link: '/data-pak/dokumen/',
    permission: 'sekretariat,admin',
  },
  {
    label: 'Export Pak',
    key: 'export_pak_sekretariat',
    icon: 'fa-solid fa-file-excel',
    link: '/data-pak/export/',
    permission: 'sekretariat,admin',
  },
  {
    label: 'Data Dupak Penilai',
    key: 'data_dupak_penilai',
    icon: 'fa-solid fa-table',
    link: '/data-dupak/table-penilai',
    permission: 'penilai',
  },
  {
    label: 'Pengaturan PAK',
    key: 'pengaturan_pak',
    icon: 'fa-solid fa-cog',
    link: '/pengaturan/pak',
    permission: 'sekretariat,admin',
  },
  {
    label: 'Pengaturan Dupak',
    key: 'pengaturan_dupak',
    permission: 'admin',
    subMenu: [
      {
        label: 'Unsur Kegiatan',
        key: 'pengaturan_unsur',
        icon: 'fa-solid fa-table',
        link: '/pengaturan/unsur',
        permission: 'admin',
      },
      {
        label: 'Sub Unsur Kegiatan',
        key: 'pengaturan_subunsur',
        icon: 'fa-solid fa-table',
        link: '/pengaturan/subunsur',
        permission: 'admin',
      },
      {
        label: 'Kegiatan',
        key: 'pengaturan_kegiatan',
        icon: 'fa-solid fa-table',
        link: '/pengaturan/kegiatan',
        permission: 'admin',
      },
      {
        label: 'Preview Dupak',
        key: 'preview_dupak',
        icon: 'fa-solid fa-file',
        link: '/preview/dupak',
        permission: 'admin',
      },
    ],
  },
  {
    label: 'Pengaturan Pengguna',
    key: 'pengaturan_user',
    permission: 'admin',
    subMenu: [
      {
        label: 'Pejabat Sipulpen',
        key: 'pengaturan_pejabat',
        icon: 'fa-solid fa-users',
        link: '/pengaturan/pejabat',
      },
      {
        label: 'Admin Sipulpen',
        key: 'pengaturan_admin',
        icon: 'fa-solid fa-user-gear',
        link: '/pengaturan/admin',
      },
      {
        label: 'User Sipulpen',
        key: 'pengaturan_user',
        icon: 'fa-solid fa-users',
        link: '/pengaturan/users',
      },
    ],
  },
]
