export default function ({ $axios, redirect, error }) {
  $axios.onError((err) => {
    const response = err.response
    // general error handler
    if (response) {
      if (response.status === 401) {
        redirect({ name: 'logout', query: { request: 'false' } })
        return
      }
      if (response.status === 503) {
        error({ statusCode: 503, message: 'Sistem dalam pemeliharaan dalam beberapa jam kedepan' })
        return
      }
    }

    return Promise.reject(err)
  })
}
