export default function ({ store, redirect, route }) {
  const arrayRole = ['admin', 'penilai', 'sekretariat']
  const currentRole = store.state.auth.user.role.name
  const isMaintenance = store.state.pengaturan.maintenance
  // const cekRole = arrayRole.find((element) => element === currentRole)
  // console.log(currentRole)

  const cekRole = arrayRole.includes(currentRole) // true
  if (!cekRole) {
    if (isMaintenance) {
      return redirect('/maintenance')
    } else if (isMaintenance === false && route.path === '/maintenance') {
      return redirect('/')
    }
  }

  // if (cekRole === undefined) {
  // }
  // if (store.state.auth.user.role.name !== admin) {
  // }
  // const isMaintenance = true
  // if (store.$auth.user.role.name !== 'admin') {
  //   return redirect('/403')
  // }
}
