<template>
  <a-menu theme="light" mode="inline" @select="selectMenu">
    <a-menu-item v-for="item in mainMenu" :key="item.key">
      <template>
        <nuxt-link
          v-if="cekRole(item.permission)"
          :to="item.link"
          :class="menuActive === item.key ? classActive : ''"
        >
          <span class="icon">
            <font-awesome-icon :icon="item.icon" size="lg" />
          </span>
          <span class="label">{{ item.label }} </span>
        </nuxt-link>
      </template>
    </a-menu-item>
    <template v-for="item in subMenu">
      <a-sub-menu :key="item.key" v-if="cekRole(item.permission)">
        <span slot="title"> {{ item.label }}</span>
        <a-menu-item v-for="childitem in item.subMenu" :key="childitem.key">
          <nuxt-link
            v-if="cekRole(item.permission)"
            :to="childitem.link"
            :class="menuActive === childitem.key ? classActive : ''"
          >
            <span class="icon">
              <font-awesome-icon :icon="childitem.icon" size="lg" />
            </span>
            <span class="label"> {{ childitem.label }} </span>
          </nuxt-link>
        </a-menu-item>
      </a-sub-menu>
    </template>
  </a-menu>
</template>
<script>
import menu from '@/components/Data/menu.js'
export default {
  data() {
    return {
      menuActive: null,
      classActive: 'router-link-exact-active router-link-active',
      menu,
      userRole: this.$auth.user.role.name,
    }
  },
  computed: {
    mainMenu() {
      const arrayData = this.menu
      return this.isMainMenu(arrayData)
    },
    subMenu() {
      const arrayData = this.menu
      return this.isSubMenu(arrayData)
    },
  },
  methods: {
    cekRole(role) {
      const menuRole = role.split(',')
      const dataRole = menuRole.find((element) => {
        return element === this.userRole || element === 'all'
      })
      return dataRole
    },
    isSubMenu(menu) {
      const result = menu.filter(({ subMenu }) => subMenu)
      return result
    },
    isMainMenu(menu) {
      const result = menu.filter(({ subMenu }) => !subMenu)
      return result
    },
    selectMenu(menu) {
      this.menuActive = menu.key
    },
  },
}
</script>
  